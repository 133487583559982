import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import OtherPage from '../components/Pages/OtherPage';

export const pageQuery = graphql`
  query OtherMdx($id: String!) {
    current: mdx(childOtherMdx: { id: { eq: $id } }) {
      id
      fields {
        slug
      }
      frontmatter {
        title
      }
      body
    }
  }
`;

export default ({ data: { current } }: PageProps<Queries.OtherMdxQuery>) => (
  <OtherPage title={current!.frontmatter!.title!} slug={'/' + current!.fields!.slug!}>
    <MDXRenderer>{current!.body!}</MDXRenderer>
  </OtherPage>
);
